import { useQuery } from "@apollo/client";
import { LinearProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import Loadable from "react-loadable";
import { Navigate, Route, Routes, useLocation } from "react-router";
import useCookie from "react-use-cookie";
import config from "../auth0/auth_config.json";
import { useAuth0 } from "../auth0/ReactAuth0Wrapper";
import ProtectedRoute from "../components/ProtectedRoute";
import { RABackdrop } from "../components/RABackdrop";
import { GA4_MEASUREMENT_ID } from "../config";
import {
  EMPLOYEE,
  PARTNERADMIN,
  SYSTEMADMIN,
  SYSTEMEMPLOYEE,
} from "../constants/authentication";
import {
  allCustomersId,
  customerNotloaded,
  environments,
} from "../constants/constants";
import { stateCategoryMap } from "../constants/state";
import { customerQuery } from "../GraphQL/customer/queries";
import { getRoleInfo } from "../GraphQL/users/queries";
import { useHasNoRoles, useIsPermitted } from "../hooks/authorization";
import NavigationWrapper from "../layouts/Wrappers/NavigationWrapper";
import PageWrapper from "../layouts/Wrappers/PageWrapper";
import { routes } from "../Routes";
import { useGlobalState } from "../state";
import { ConfigPermissionMap, RoleLevels } from "../types/authentication";
import { getLocaleDateFns } from "../utils/locale";
import { isEnvironment } from "../utils/utils";

const LoadingFrame = styled("div")(
  ({ theme }) => `
  display: flex;
  text-align: center;
  align-items: center;
  min-height: 100vh;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  width: 100%;
  color: ${theme.palette.primary.main};
`
);
ReactGA.initialize(String(GA4_MEASUREMENT_ID), {
  gaOptions: {
    debug_mode: !isEnvironment(environments.PROD),
  },
  gtagOptions: {
    debug_mode: !isEnvironment(environments.PROD),
  },
});

export const Load = (importer: any, inProps = {}, render = null) => {
  return Loadable({
    loader: importer,
    loading: () => {
      return <LinearProgress />;
    },
    render:
      render ||
      ((loaded, props) => {
        // @ts-ignore:
        let Component = loaded.default;
        return <Component {...props} {...inProps} />;
      }),
  });
};

const Home = Load(() => import("./Home"));
const Access = Load(() => import("./Configuration/Access"));
const AccessLog = Load(() => import("./Operation/Access"));
const App = Load(() => import("./Configuration/App"));
const Assist = Load(() => import("./Configuration/Assist"));
const Butler = Load(() => import("./Configuration/Butler"));
const ButlerLog = Load(() => import("./Operation/Butler"));
const ContentManagement = Load(() => import("./ContentManagement"));
const CustomerSettings = Load(() => import("./CustomerSettings"));
const Gates = Load(() => import("./Configuration/Gates"));
const GatesLog = Load(() => import("./Operation/Gates"));
const General = Load(() => import("./Configuration/General"));
const GeneralOperation = Load(() => import("./GeneralOperation"));
const Inspire = Load(() => import("./Configuration/Inspire"));
const LibraryApp = Load(() => import("./Configuration/LibraryApp"));
const LibraryAssist = Load(() => import("./Configuration/LibraryAssist"));
const Login = Load(() => import("./Login"));
const PeopleCounter = Load(() => import("./Configuration/PeopleCounter"));
const PeopleCounterLog = Load(() => import("./Operation/PeopleCounter"));
const Profile = Load(() => import("./Profile"));
const ThemeComponent = Load(() => import("./Theme"));
const Users = Load(() => import("./Users"));
const _404 = Load(() => import("./_404"));

interface AppRouterProps {
  authToken?: string;
}

export default function AppRouter(props: AppRouterProps) {
  const { loading: authLoading, isAuthenticated, user } = useAuth0();
  const location = useLocation();
  const [authToken, setAuthToken] = useGlobalState(stateCategoryMap.AUTH_TOKEN);
  const [roleLevels, setGlobalRoleLevels] = useGlobalState(
    stateCategoryMap.ROLE_LEVELS
  );
  const [globalCustomer, setGlobalCustomer] = useGlobalState(
    stateCategoryMap.CUSTOMER
  );
  const [permissionMap, setGlobalPermissionMap] = useGlobalState(
    stateCategoryMap.CONFIG_PERMISSION_MAP
  );
  const [customerCookie] = useCookie("customerId", "");
  const [hideLeftMenu, setHideLeftMenu] = useState(true);
  const [refetchAttempts, setRefetchAttempts] = useState(0);
  const { isPermitted: advancedPermission, completed } =
    useIsPermitted(PARTNERADMIN);
  const { isPermitted: viewPermission } = useIsPermitted(EMPLOYEE);
  const noPermission = useHasNoRoles();
  const showNavIfAuthenticated = [routes.home];
  const neverShowNav = [
    routes.login,
    routes._404,
    routes.theme,
    routes.customers.configuration.select,
  ];
  const allPaths = [
    routes._404,
    routes.access.root + "/*",
    routes.app.root + "/*",
    routes.assist.root + "/*",
    routes.butler.root + "/*",
    routes.customers.configuration.select,
    routes.gates.root + "/*",
    routes.general.content.root + "/*",
    routes.general.customer_settings.customer_groups,
    routes.general.root + "/*",
    routes.general.users.user_administration,
    routes.inspire.root + "/*",
    routes.library_app.root + "/*",
    routes.people_counter.root + "/*",
    routes.theme,
  ];
  const protectedPaths = [routes.customers.configuration.select, routes.theme];
  const skip =
    globalCustomer.id === "" ||
    globalCustomer.id === customerNotloaded ||
    globalCustomer.id === allCustomersId ||
    authToken === undefined ||
    authLoading;
  const { data: roleInfoData, loading: roleInfoLoading } =
    useQuery(getRoleInfo);
  const {
    error: customerError,
    loading: customerLoading,
    data: customerData,
    refetch: refetchCustomer,
  } = useQuery(customerQuery, {
    variables: { customerId: globalCustomer.id },
    skip: skip,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
    });
  }, [location]);

  useEffect(() => {
    if (
      (!isAuthenticated &&
        showNavIfAuthenticated.includes(location.pathname)) ||
      neverShowNav.includes(location.pathname)
    ) {
      setHideLeftMenu(true);
    } else {
      setHideLeftMenu(false);
    }
  }, [location.pathname, isAuthenticated]);

  useEffect(() => {
    if (props.authToken) {
      setAuthToken(props.authToken);
    }
  }, [props.authToken]);

  useEffect(() => {
    if (!isAuthenticated) {
      setGlobalCustomer({ ...globalCustomer, id: "" });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (customerError) {
      if (refetchAttempts < 3) {
        const timer = setTimeout(() => {
          setRefetchAttempts(refetchAttempts + 1);
          refetchCustomer();
        }, 1000);
        return () => clearTimeout(timer);
      } else {
        setGlobalCustomer({ ...globalCustomer, id: "" });
      }
    }
  }, [customerError]);

  useEffect(() => {
    if (!!roleInfoData?.getRoleInfo?.roleLevels) {
      const fetchedRoleLevels: RoleLevels =
        roleInfoData?.getRoleInfo?.roleLevels;
      if (!isEqual(roleLevels, fetchedRoleLevels)) {
        setGlobalRoleLevels(fetchedRoleLevels);
      }
    }
    if (!!roleInfoData?.getRoleInfo?.configPermissionMap) {
      const fetchedPermissionMap: ConfigPermissionMap =
        roleInfoData?.getRoleInfo?.configPermissionMap;
      if (!isEqual(permissionMap, fetchedPermissionMap)) {
        setGlobalPermissionMap(fetchedPermissionMap);
      }
    }
  }, [roleInfoData]);

  useEffect(() => {
    if (customerData) {
      const { customerId, ...rest } = customerData.customer;
      setGlobalCustomer({ id: customerId, ...rest });
    }
  }, [customerData]);

  useEffect(() => {
    if (globalCustomer.update) {
      (async () => {
        await refetchCustomer();
        setGlobalCustomer({ ...globalCustomer, update: false });
      })();
    }
  }, [globalCustomer]);

  function setGA4CustomerId(id: string) {
    ReactGA.gtag("set", "user_properties", {
      customer_id: id,
    });
  }
  useEffect(() => {
    if (user && user[config.roleUrl]) {
      ReactGA.gtag("set", "user_properties", {
        roles: user[config.roleUrl],
      });
    }

    if (advancedPermission && customerCookie !== "") {
      setGlobalCustomer({
        id: customerCookie,
        name: "",
        customerError: false,
        loading: false,
        update: false,
        products: [],
        partnerId: "",
      });
    } else if (viewPermission) {
      if (user[config.customerUrl]) {
        const customerID: string = user[config.customerUrl];
        setGlobalCustomer({
          id: customerID,
          name: "",
          customerError: false,
          loading: true,
          update: false,
          products: [],
          partnerId: "",
        });
      } else {
        setGlobalCustomer({
          id: "",
          name: "",
          customerError: true,
          loading: false,
          update: false,
          products: [],
          partnerId: "",
        });
      }
    }
  }, [user, customerCookie, roleLevels]);

  useEffect(() => {
    if (
      globalCustomer.id !== "" &&
      globalCustomer.id !== customerNotloaded &&
      user &&
      !!authToken
    ) {
      if (globalCustomer.id === allCustomersId) {
        setGA4CustomerId("all");
        setGlobalCustomer({
          id: globalCustomer.id,
          name: "",
          customerError: false,
          loading: false,
          update: false,
          products: [],
          partnerId: "",
        });
      } else {
        setGA4CustomerId(globalCustomer.id);
        setGlobalCustomer({
          id: globalCustomer.id,
          name: "",
          customerError: false,
          loading: true,
          update: false,
          products: [],
          partnerId: "",
        });
        refetchCustomer();
      }
    }
  }, [globalCustomer.id, user, authToken]);

  function customerNotYetLoaded() {
    return globalCustomer.id === customerNotloaded;
  }
  function noCustomer() {
    return globalCustomer.id === "" && customerCookie === "";
  }
  function userHasNoRole() {
    if (!authLoading && isAuthenticated) {
      return noPermission;
    }
    return false;
  }
  function userIsNotLoggedIn() {
    if (!authLoading && !isAuthenticated) {
      return true;
    }
    return false;
  }

  function userDoesNotHaveAdvancedPermissions() {
    if (!advancedPermission && completed) {
      return true;
    }
    return false;
  }

  if (authLoading || customerNotYetLoaded()) {
    return <LoadingFrame>Loading...</LoadingFrame>;
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={getLocaleDateFns()}
    >
      <RABackdrop open={customerLoading || roleInfoLoading || authLoading} />
      <NavigationWrapper hideLeftMenu={hideLeftMenu}>
        <PageWrapper hideHeader={false} isAuthenticated={isAuthenticated}>
          <Routes>
            {userIsNotLoggedIn() &&
              allPaths.map((p, index) => (
                <Route
                  path={p}
                  key={index}
                  element={<Navigate to={routes.home} replace />}
                />
              ))}
            {userHasNoRole() && (
              <>
                <Route path={routes.home} element={<_404 />} />
                <Route path={routes.profile} element={<_404 />} />
                {allPaths.map((p, index) => (
                  <Route
                    path={p}
                    key={index}
                    element={<Navigate to={routes.home} replace />}
                  />
                ))}
              </>
            )}
            <Route
              path={routes.root}
              element={<Navigate to={routes.home} replace />}
            />
            <Route path={routes.login + "/*"} element={<Login />} />
            <Route path={routes.home} element={<Home />} />
            {noCustomer() &&
              allPaths.map((p, index) => (
                <Route
                  path={p}
                  key={index}
                  element={<Navigate to={routes.home} replace />}
                />
              ))}
            {userDoesNotHaveAdvancedPermissions() &&
              protectedPaths.map((p, index) => (
                <Route
                  path={p}
                  key={index}
                  element={<Navigate to={routes.home} replace />}
                />
              ))}
            <Route path={routes.theme + "/*"} element={<ThemeComponent />} />
            <Route
              path={routes.profile}
              element={
                <ProtectedRoute path={routes.profile} element={<Profile />} />
              }
            />
            <Route
              path={routes.general.customer_settings.customer_groups}
              element={
                <ProtectedRoute
                  path="/customer_settings"
                  element={<CustomerSettings />}
                  requiredRoleLevel={SYSTEMADMIN}
                  onNotAuthorizedRedirectTo={routes.home}
                />
              }
            />
            <Route
              path={routes.general.users.user_administration}
              element={
                <ProtectedRoute
                  path="/users"
                  element={<Users />}
                  requiredRoleLevel={EMPLOYEE}
                  onNotAuthorizedRedirectTo={routes.home}
                />
              }
            />
            <Route
              path={routes.general.operation.overview}
              element={
                <ProtectedRoute
                  path={routes.general.operation.overview}
                  element={
                    <div>
                      <GeneralOperation />
                    </div>
                  }
                  requiredRoleLevel={SYSTEMEMPLOYEE}
                  onNotAuthorizedRedirectTo={routes.home}
                />
              }
            />
            <Route
              path={routes.general.content.root + "/*"}
              element={
                <ProtectedRoute
                  path={routes.general.content.root + "/*"}
                  element={<ContentManagement />}
                  requiredRoleLevel={EMPLOYEE}
                  onNotAuthorizedRedirectTo={routes.home}
                />
              }
            />
            <Route
              path={routes.gates.operation.root + "/*"}
              element={
                <ProtectedRoute
                  path={routes.gates.root + "/*"}
                  element={<GatesLog />}
                />
              }
            />
            <Route
              path={routes.access.operation.root + "/*"}
              element={
                <ProtectedRoute
                  path={routes.access.root + "/*"}
                  element={<AccessLog />}
                />
              }
            />
            <Route
              path={routes.butler.operation.root + "/*"}
              element={
                <ProtectedRoute
                  path={routes.butler.root + "/*"}
                  element={<ButlerLog />}
                />
              }
            />
            <Route
              path={routes.butler.root + "/*"}
              element={
                <ProtectedRoute
                  path={routes.butler.root + "/*"}
                  element={<Butler />}
                />
              }
            />
            <Route
              path={routes.inspire.root + "/*"}
              element={
                <ProtectedRoute
                  path={routes.inspire.root + "/*"}
                  element={<Inspire />}
                />
              }
            />
            <Route
              path={routes.general.root + "/*"}
              element={
                <ProtectedRoute
                  path={routes.general.root + "/*"}
                  element={<General />}
                />
              }
            />
            <Route
              path={routes.app.root + "/*"}
              element={
                <ProtectedRoute
                  path={routes.app.root + "/*"}
                  element={<App />}
                />
              }
            />
            <Route
              path={routes.library_app.root + "/*"}
              element={
                <ProtectedRoute
                  path={routes.library_app.root + "/*"}
                  element={<LibraryApp />}
                />
              }
            />
            <Route
              path={routes.library_assist.root + "/*"}
              element={
                <ProtectedRoute
                  path={routes.library_assist.root + "/*"}
                  element={<LibraryAssist />}
                />
              }
            />
            <Route
              path={routes.assist.root + "/*"}
              element={
                <ProtectedRoute
                  path={routes.assist.root + "/*"}
                  element={<Assist />}
                />
              }
            />
            <Route
              path={routes.gates.root + "/*"}
              element={
                <ProtectedRoute
                  path={routes.gates.root + "/*"}
                  element={<Gates />}
                />
              }
            />
            <Route
              path={routes.access.root + "/*"}
              element={
                <ProtectedRoute
                  path={routes.access.root + "/*"}
                  element={<Access />}
                />
              }
            />
            <Route
              path={routes.people_counter.operation.root + "/*"}
              element={
                <ProtectedRoute
                  path={routes.people_counter.root + "/*"}
                  element={<PeopleCounterLog />}
                />
              }
            />
            <Route
              path={routes.people_counter.root + "/*"}
              element={
                <ProtectedRoute
                  path={routes.people_counter.root + "/*"}
                  element={<PeopleCounter />}
                />
              }
            />
            <Route path={routes._404} element={<_404 />} />
          </Routes>
        </PageWrapper>
      </NavigationWrapper>
    </LocalizationProvider>
  );
}
